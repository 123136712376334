<template>
  <div class="iss-main theme-bg">
    <div class="dashboard-content">
      <!--   todo 无数据时展示  validP  -->
      <tips-bar
        text="请校验疑似重复联系人和无效联系人，并手动纠错。"
        v-if="validP > 0 && cloudstate"
      >
        <template #btnList>
          <!--          <a-popconfirm-->
          <!--            placement="left"-->
          <!--            ok-text="确认"-->
          <!--            cancel-text="取消"-->
          <!--            @confirm="clickHandle"-->
          <!--            v-if="nextVisible"-->
          <!--          >-->
          <!--            <template #title>-->
          <!--              <p>-->
          <!--                本次未处理完的无效联系人和疑似重复联系人，您可在首页待清洗联系人中再次处理。-->
          <!--              </p>-->
          <!--            </template>-->
          <!--            <div class="btn"   @click="clickHandle">下一步66</div>-->
          <!--          </a-popconfirm>-->
          <!--       v-if="nextVisible"   -->
          <div class="btn" v-if="nextVisible" @click="clickHandle">下一步</div>

          <div v-else-if="!nextVisible" class="btn" @click="clickHandle">
            下一步
          </div>
        </template>
      </tips-bar>

      <!--   云同步    -->
      <tips-bar
        text="请点击左下角,下载人脉宝盒APP同步联系人，若已完成同步请刷新当前页面。"
        v-else-if="validP === 0 && cloudstate"
      >
        <template #btnList>
          <a-popconfirm
            placement="left"
            ok-text="确认"
            cancel-text="取消"
            @confirm="clickHandle"
            v-if="nextVisible"
          >
            <template #title>
              <p>
                您无效联系人和疑似重复联系人还未处理完，您确认进入下一步吗？
              </p>
            </template>
            <div class="btn">下一步</div>
          </a-popconfirm>
          <div v-else-if="!nextVisible" class="newBut">下一步</div>
        </template>
      </tips-bar>

      <!--    未云同步  -->
      <tips-bar
        text="无法使用pc端功能请在APP设置中开启数据云同步，完成同步后刷新当前页面。"
        v-else
      >
        <template #btnList>
          <a-popconfirm
            placement="left"
            ok-text="确认"
            cancel-text="取消"
            @confirm="clickHandle"
            v-if="nextVisible"
          >
            <template #title>
              <p>
                您无效联系人和疑似重复联系人还未处理完，您确认进入下一步吗？
              </p>
            </template>
            <div class="btn">下一步</div>
          </a-popconfirm>
          <div v-else-if="!nextVisible" class="newBut">下一步</div>
        </template>
      </tips-bar>

      <div class="operation">
        <div class="success">
          <div class="link-to" @click="routeTo('valid')">有效联系人 ></div>
          <img src="@/assets/images/dashboard/success.png" alt="" />
          <template v-if="validP > 0">
            <!--          有数据根据 0 1展示   -->
            <div v-if="importStatus === '1'">
              <!--              <p class="fs-20 fw-8">恭喜您，同步成功！</p>-->
              <div class="fs-20 fnCenton">共{{ validP }}个有效联系人!</div>
              <p class="fs-14 text-1">
                已为您自动合并了完全重复联系人，并清理了姓名和号码中的标点符号以及空格。
              </p>
            </div>

            <div v-else class="textC">
              <p class="fs-20 fw-8">恭喜您，同步成功！</p>
              <p class="fs-20">
                共{{ total }}个联系人，其中有效联系人
                <span class="l-text-primary">
                  {{ validP }}
                </span>
                个！
              </p>
              <p class="fs-14 textOne">
                已为您自动合并了完全重复联系人，并清理了姓名和号码中的标点符号以及空格。
              </p>
            </div>
          </template>

          <template v-else>
            <p class="fs-20">暂无有效联系人</p>

            <div v-if="cloudstate">
              <!--    从弹窗或者今天红色按钮进来 0        -->
              <div v-if="importStatus === '0'">
                <div class="fs-20 fnCenton">
                  共{{ total }}个联系人，其中有效联系人
                  <span class="l-text-primary">
                    {{ validP }}
                  </span>
                  个！
                </div>
                <p class="fs-14 text-1">刷新页面后展示已完成同步的联系人数据</p>
              </div>

              <!--      从今天点击绿色进来显示或者没有联系人数据点击完成进来 1      -->
              <div v-else>
                <!--          没有有效联系人展示       -->
                <p class="fs-16 text-1" v-if="validP === 0">
                  请将无效联系人和疑似重复联系人手动纠错后，转为有效联系人！
                </p>

                <div v-else>
                  <div class="fs-20 fnCenton">共{{ validP }}个有效联系人!</div>
                  <p class="fs-16 text-1">
                    请将无效联系人和疑似重复联系人手动纠错后，转为有效联系人！
                  </p>
                </div>
              </div>
            </div>

            <div v-else>
              <p class="fs-14 text-1">
                由于您未开启数据云同步PC端无法获取到您的数据请在APP设置中开启云同步
              </p>
            </div>
          </template>
        </div>
        <div class="fail">
          <div class="invalid">
            <div class="link-to" @click="routeTo('invalid')">查看详情 ></div>
            <div class="fail-l">
              <img src="@/assets/images/dashboard/invalid.png" alt="" />
              <div class="l-text-primary fs-20 fw-8">
                无效联系人（{{ invalid }}）
              </div>
              <div class="fs-16 mt-8">无姓名和电话号码的联系人</div>
            </div>
            <div class="fail-r">
              <a-popconfirm
                placement="left"
                ok-text="确认"
                cancel-text="取消"
                @confirm="clearAll"
                v-if="cloudstate"
              >
                <template #title>
                  <p>您确认删除所有无效联系人数据吗？</p>
                </template>
                <div class="l-btn-primary mb-40">一键清除</div>
              </a-popconfirm>

              <div class="qingchu mb-40" v-else>一键清除</div>
            </div>
          </div>
          <div class="repeating">
            <div class="link-to" @click="routeTo('repeating')">查看详情 ></div>
            <div class="fail-l">
              <img src="@/assets/images/dashboard/repeating.png" alt="" />
              <div class="l-text-primary fs-20 fw-8">
                疑似重复联系人（{{ repeating }}）
              </div>
              <div class="fs-16 mt-8">电话或姓名有重复的联系人</div>
            </div>
            <div class="fail-r">
              <div
                class="l-btn-primary mb-40"
                @click="routeTo('repeating')"
                v-if="cloudstate"
              >
                手动合并
              </div>

              <div class="qingchu mb-40" v-else>手动合并</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <open-ai
      v-model:visible="visibleAi"
      :init="initValue"
      @fnOk="handleOpenAi"
    />
  </div>
</template>

<script>
import { Popconfirm, message } from 'ant-design-vue';
import tipsBar from './components/tipsBar';
import { computed, reactive, toRefs } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import dashboardApi from '@/api/dashboard';
import openAi from '@/views/dashboard/components/openAI';
import * as icons from '@ant-design/icons-vue';

export default {
  name: 'dashboard',
  components: {
    tipsBar,
    APopconfirm: Popconfirm,
    openAi,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const states = reactive({
      repeating: 0,
      invalid: 0,
      validP: 0,
      total: 0,
      cloudstate: false,
      cloudText: '',
      cloudON: '',
      successData: '请校验疑似重复联系人和无效联系人，并手动纠错。',
      text: '',
      visibleAi: false,
      initValue: {},
      importStatus: route.query.importStatus,
    });
    console.log(
      'route.query.importStatus',
      route.query.importStatus,
      typeof route.query.importStatus
    );
    const nextVisible = computed(() => {
      return states.repeating > 0 || states.invalid > 0;
    });
    const clickHandle = () => {
      // 暂时关闭
      if (states.validP === 0) {
        message.info('请添加有效联系人');
        return;
      }

      states.visibleAi = true;
      states.initValue.formTo = 'dashboard';

      // 点击下一步时调取号码重复接口,有长度点击确认去号码重复页面,否则弹窗ai识别页面
      // dashboardApi
      //   .getRepeatPhone({
      //     userId: store.state.account.user.id,
      //     importSituation: route.query.importStatus,
      //   })
      //   .then(res => {
      //     if (res.length > 0) {
      //       Modal.confirm({
      //         title: `本次未处理完的无效联系人和疑似重复联系人，您可在首页待清洗联系人中再次处理。`,
      //         icon: createVNode(icons['ExclamationCircleOutlined']),
      //         okText: '确认',
      //         onOk() {
      //           routeTo('repeatPhone');
      //         },
      //       });
      //     } else {
      //       // routeTo('dataRecognition');
      //
      //       // todo 打开弹窗
      //       states.visibleAi = true;
      //       states.initValue.formTo = 'dashboard';
      //     }
      //   });
    };
    const clearAll = () => {
      dashboardApi
        .clearInvalid({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          if (res) {
            message.success('清除成功');
            getData();
          }
        });
    };
    // 设置用户导入行为页面信息 /dashboard  设置9
    dashboardApi
      .setBehaviorPage({
        userId: store.state.account.user.id,
        importSituation: route.query.importStatus,
        pageName: '/dashboard',
      })
      .then(res => {
        if (res) {
          console.log('res', res);
        }
      });

    const routeTo = name => {
      const arr = [
        'correction',
        'invalid',
        'repeating',
        'valid',
        'repeatPhone',
        'dataRecognition',
      ];
      if (arr.includes(name)) {
        router.push({
          name: name,
          query: route.query,
        });
      }
    };
    const getData = () => {
      dashboardApi
        .getImportData({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          if (res) {
            states.total = parseInt(res.count);
            states.invalid = parseInt(res.invalid);
            states.validP = parseInt(res.effective);
            console.log('validP', states.validP, typeof states.validP);
            states.repeating = parseInt(res.repeat);
          }
        });
    };
    // getData();

    //   查询是否云端存储
    dashboardApi.getIsWhetherCloud({}).then(res => {
      if (res) {
        states.cloudstate = res;
        getData();
      } else {
        states.cloudstate = res;
      }
    });

    return {
      ...toRefs(states),
      clickHandle,
      routeTo,
      clearAll,
      icons,
      nextVisible,
      handleOpenAi(value) {
        states.visibleAi = value;
      },
    };
  },
};
</script>

<style lang="less" scoped>
.theme-bg {
  background: linear-gradient(245deg, #ffedde 1%, #fff8f1 100%);
}
.dashboard-content {
  width: 90%;
  margin: 0 auto;
  padding-top: 40px;
}
.operation {
  margin: 20px 0;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .success {
    position: relative;
    height: 100%;
    flex: 1;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 180px;
      margin-bottom: 16px;
    }
    .text-1 {
      width: 300px;
      text-align: center;
      color: #999999;
    }
  }
  .fail {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .invalid,
    .repeating {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      flex: 1;
      background-color: #fff;
      border-radius: 16px;
    }
    .invalid {
      margin-bottom: 20px;
    }
    .fail-l {
      flex: 1;
      height: 100%;
      padding-left: 16px;
      img {
        width: 100px;
        margin-bottom: 10px;
      }
      & > div {
        padding-left: 18px;
      }
    }
    .fail-r {
      width: 120px;
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}
.link-to {
  font-size: 14px;
  color: @primary-color;
  position: absolute;
  top: 20px;
  right: 28px;
  cursor: pointer;
}
.btn {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  cursor: pointer;
}
.newBut {
  width: 100px;
  height: 42px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  opacity: 0.5;
}
.qingchu {
  width: fit-content;
  padding: 8px 20px;
  background: linear-gradient(270deg, #ffb64b 0%, #ff7b00 100%);
  border-radius: 8px;
  font-size: 14px;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}
.fnCenton {
  text-align: center !important;
}
.textC {
  text-align: center;
}
.textOne {
  text-align: center;
  color: #999999;
}
</style>
