<template>
  <div class="modalClass" ref="container">
    <a-modal
      :visible="visible"
      :width="720"
      :destroyOnClose="true"
      :getContainer="container"
      @cancel="handleCancel"
      :bodyStyle="{
        background:
          'linear-gradient(0deg, rgba(253, 246, 236, 0.5) 0%, rgba(253, 246, 236, 0) 63%);',
      }"
    >
      <tips-bar
        text="本次未处理完的无效联系人和疑似重复联系人，您可在首页待清洗联系人中再次处理。下一步整理联系人姓名、公司、职位信息，将连写信息进行拆分。"
        class="mt-76"
        v-if="type === 'dashboard'"
      >
      </tips-bar>
      <!--       state.type = 'repeatPhone' -->
      <tips-bar
        text="下一步整理联系人姓名、公司、职位信息，将连写信息进行拆分。"
        class="mt-76"
        v-else
      >
      </tips-bar>
      <div class="welcome-modal">
        <img
          src="@/assets/images/dashboard/openAi.png"
          alt=""
          class="qrcode-bg"
        />
      </div>

      <!--      <div class="borBut">-->
      <!--        <div class="shoudong">手动整理</div>-->
      <!--        <div class="Ai">AI智能识别辅助</div>-->
      <!--      </div>-->

      <template v-slot:footer>
        <a-button class="butL" @click="handleManual">手动整理</a-button>
        <a-button class="butR" type="primary" @click="handleOk"
          >AI智能识别辅助</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref, toRefs, reactive, watch } from 'vue';
import dashboardApi from '@/api/dashboard.js';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import tipsBar from './tipsBar';
// import {message} from "ant-design-vue";
// import todayApi from "@/api/today";

export default {
  components: {
    tipsBar,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const container = ref();
    const store = useStore();
    const route = useRoute();
    // const loading = ref(false);
    const router = useRouter();
    // const intervalId = ref(true);
    const state = reactive({
      cloudstate: null,
      type: '',
    });

    // const getLoadingStatus = () => {
    //   dashboardApi
    //       .getStatus({
    //         userId: store.state.account.user.id,
    //       })
    //       .then(res => {
    //         if (res) {
    //           if (res === 'start' || res === 'importing') {
    //             loading.value = true;
    //             // state.visible = false;
    //           } else if (res === 'complete') {
    //             clearInterval(intervalId.value);
    //             console.log('定时器已清除22');
    //             context.emit('fnOk', false);
    //             router.push({
    //               path: 'dashboard',
    //               query: {
    //                 importStatus: 0,
    //               },
    //             });
    //           }
    //         }
    //       });
    // };

    // 设置联系人信息是否自动识别
    const setIdentifyAutomatic = type => {
      dashboardApi
        .setIdentifyAutomatic({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
          status: type,
        })
        .then(() => {});
    };

    //  有效联系人信息识别
    const queryImportContactInfoIdentify = () => {
      dashboardApi
        .queryImportContactInfoIdentify({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(() => {});
    };

    // 查询识别状态
    const getImportContactInfoIdentify = () => {
      dashboardApi
        .getImportContactInfoIdentify({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          // console.log('res555', res);
          if (res.condition === 'completed') {
            // 如果正在识别中就不用调和开始,完成调
            //  有效联系人信息识别
            // queryImportContactInfoIdentify();
            routeTo('dataRecognition');
          } else {
            routeTo('AiIdentify');
          }
        });
    };

    watch(
      () => props.init,
      value => {
        if (value === 'dashboard') {
          state.type = 'dashboard';
        } else {
          state.type = 'repeatPhone';
        }
      }
    );

    // dashboardApi
    //     .setBehaviorPage({
    //       userId: store.state.account.user.id,
    //       importSituation: 0,
    //       pageName: 'welcome',
    //     })
    //     .then(res => {
    //       if (res) {
    //         console.log('45646465', res)
    //       }
    //     });
    //  获取用户导入行为页面信息
    // dashboardApi
    //     .getBehaviorPage({
    //       userId: store.state.account.user.id,
    //       importSituation: 0,
    //     })
    //     .then(res => {
    //       // 有数据就去我设置的页面，否去dashboard页面
    //       if (res) {
    //         console.log('res66', res);
    //         router.push({
    //           path: res,
    //           query: {
    //             importStatus: 0,
    //           },
    //         });
    //       } else {
    //         router.push({
    //           name: 'dashboard',
    //           query: {
    //             importStatus: 0,
    //           },
    //         });
    //       }
    //     });

    onMounted(() => {
      // intervalId.value = setInterval(() => {
      //   console.log('定时器正在运行...');
      //   getLoadingStatus();
      // }, 5000);
    });
    // 在组件销毁之前清除定时器
    onBeforeUnmount(() => {
      // if (intervalId.value) {
      //   clearInterval(intervalId.value);
      //   console.log('定时器已清除');
      // }
    });

    const handleCancel = () => {
      // clearInterval(intervalId.value);
      context.emit('fnOk', false);
    };
    const handleManual = () => {
      setIdentifyAutomatic('manual');
      routeTo('dataRecognition');
      context.emit('fnOk', false);
    };

    const handleOk = () => {
      // 设置AI
      setIdentifyAutomatic('AI');

      dashboardApi
        .getImportContactInfoIdentify({
          userId: store.state.account.user.id,
          importSituation: route.query.importStatus,
        })
        .then(res => {
          // console.log('res555', res);
          if (res.condition === 'completed') {
            // 如果正在识别中就不用调和开始,完成调

            //  有效联系人信息识别
            queryImportContactInfoIdentify();
            setTimeout(() => {
              getImportContactInfoIdentify();
              context.emit('fnOk', false);
            }, 1000);
          } else {
            // 识别中 和 开始
            routeTo('AiIdentify');
          }
        });

      //   查看有没有数据正在识别中
      // queryImportContactInfoIdentify();
    };

    const routeTo = name => {
      const arr = [
        'correction',
        'invalid',
        'repeating',
        'valid',
        'repeatPhone',
        'dataRecognition',
        'AiIdentify',
      ];
      if (arr.includes(name)) {
        router.push({
          name: name,
          query: route.query,
        });
      }
    };

    return {
      container,
      ...toRefs(state),
      handleCancel,
      handleManual,
      handleOk,
      routeTo,
      // gotoRoute() {
      //   context.emit('fnOk', false);
      //   clearInterval(intervalId.value);
      //   router.push('/dashboard?importStatus=0');
      // },
    };
  },
};
</script>

<style scoped lang="less">
.welcome-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0px 32px;
  .qrcode-bg {
    width: 653px;
  }

  .welcome-modal-r {
    border-left: 1px solid #eeeeee;
    padding-left: 50px;
    img {
      width: 100%;
    }
    .openApp {
      margin-top: 36px;
      margin-bottom: 20px;
    }
    .butClass {
      padding: 10px 54px;
      background: linear-gradient(270deg, #fdbc25 0%, #ff8e31 37%, #ff7b00 86%);
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      //width: 53%;
      margin-left: 80px;

      position: absolute;
      top: 416px;
      right: 115px;
      width: 188px;
    }
  }
}
.borBut {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .shoudong {
  }
  .Ai {
  }
}
.modalClass /deep/ .ant-modal-body {
  //padding-top: 76px;
  padding: 76px 32px 0px;
}
.modalClass /deep/ .butL {
  width: 128px;
}
.modalClass /deep/ .ant-modal-footer {
  //padding: 36px 224px;
  padding: 0px 224px 36px;
}
.modalClass {
  :deep(.ant-btn) {
    padding: 5px 15px;
    height: 44px;
  }
}
.tips {
  display: flex;
  align-items: baseline;
}
.tips-text {
  font-size: 16px !important;
}
.tips-content {
  background: #fff;
  padding: 0px;
}
</style>
